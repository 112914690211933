import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  DeleteIcon,
  EditIcon,
  LinkIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import SearchBar from "../SearchBar";
import { FileTextIcon } from "lucide-react";
import { exportData } from "../../utils/reportingDownloads";
import _, { set } from "lodash";

const Datatable = ({
  headerColumns,
  data,
  dataTableHeading,
  handleDelete,
  handleUpdate,
  handleViewMore,
  handlePostLocationToSanral,
  handlePostTariffToSanral,
  isSearch,
  isActions,
  isLoading,
  pageSizeLimit = 5,
  isDownloadActions = false,
}: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sorting, setSorting] = useState({ column: "", direction: "asc" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [downloadLoading, setDownloadLoading] = useState("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);
  const cancelRef: any = React.useRef();
  const toast = useToast();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 5,
    });

  const handleSort = (column: string) => {
    const newSorting = {
      column,
      direction:
        sorting.column === column && sorting.direction === "asc"
          ? "desc"
          : "asc",
    };
    setSorting(newSorting);
  };

  const sortedData = React.useMemo(() => {
    if (!sorting.column) return data;

    console.log(data);

    return [...data].sort((a: any, b: any) => {
      const aValue = a[sorting.column];
      const bValue = b[sorting.column];

      if (aValue < bValue) {
        return sorting.direction === "asc" ? -1 : 1;
      }

      if (aValue > bValue) {
        return sorting.direction === "asc" ? 1 : -1;
      }

      return 0;
    });
  }, [data, sorting]);

  const filteredData = React.useMemo(() => {
    if (!searchTerm) {
      return sortedData;
    }

    if (!_.isEmpty(sortedData)) {
      return sortedData.filter((item: any) =>
        Object.values(item).some((value: any) => {
          if (value) {
            return value
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          }
          return value;
        })
      );
    }
  }, [sortedData, searchTerm]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // check if the actions are required
  let columns;

  const enhancedColumns = headerColumns.map((column: any) => ({
    ...column,
    sortType: (rowA: any, rowB: any, columnId: any) => {
      return rowA.values[columnId]
        .toLowerCase()
        .localeCompare(rowB.values[columnId].toLowerCase());
    },
  }));

  if (isActions) {
    columns = [
      ...enhancedColumns,
      {
        Header: "Actions",
        accessorKey: "actions",
        cell: ({ row }: { row: Row<any> }) => (
          <Flex>
            {dataTableHeading === "Locations" && (
              <Tooltip label="Retry posting location">
                <IconButton
                  colorScheme={
                    row?.original?.external_sync_status === "pending"
                      ? `orange`
                      : row?.original?.external_sync_status === `success`
                      ? `green`
                      : `red`
                  }
                  icon={<RepeatIcon />}
                  onClick={() => handlePostLocationToSanral(row.original)}
                  size="md"
                  isDisabled={row?.original?.external_sync_status === "success"}
                  aria-label="Second Action"
                />
              </Tooltip>
            )}

            {dataTableHeading === "Tariffs" && (
              <Tooltip label="Retry posting tariff">
                <IconButton
                  colorScheme={
                    row?.original?.external_sync_status === "pending"
                      ? `orange`
                      : row?.original?.external_sync_status === `success`
                      ? `green`
                      : `red`
                  }
                  icon={<RepeatIcon />}
                  onClick={() => handlePostTariffToSanral(row.original)}
                  size="md"
                  isDisabled={row?.original?.external_sync_status === "success"}
                  aria-label="Second Action"
                />
              </Tooltip>
            )}

            {row.original.route_direction ? (
              <Tooltip label="Delete">
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteRow(row.original)}
                  variant="ghost"
                  size="md"
                  aria-label="First Action"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip label="View more details">
                  <IconButton
                    icon={<LinkIcon />}
                    onClick={() => handleViewMore(row.original)}
                    variant="ghost"
                    size="md"
                    aria-label="Second Action"
                  />
                </Tooltip>

                <Tooltip label="Edit">
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() => handleUpdate(row.original)}
                    variant="ghost"
                    size="md"
                    aria-label="Second Action"
                  />
                </Tooltip>

                <Tooltip label="Delete">
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteRow(row.original)}
                    variant="ghost"
                    size="md"
                    aria-label="First Action"
                  />
                </Tooltip>
              </>
            )}
          </Flex>
        ),
      },
    ];
  } else {
    columns = [...headerColumns];
  }

  const renderColumn = (id: string, cell: any, row: any) => {
    switch (id) {
      default:
        if (typeof cell.column.columnDef.cell === "function") {
          const CellComponent = cell.column.columnDef
            .cell as React.ComponentType;
          return <CellComponent {...cell.getContext()} />;
        } else {
          return null;
        }
    }
  };

  const handleDeleteRow = (row: any) => {
    setItem(row);
    onOpen();
  };

  const deleteItem = (item: any) => {
    if (dataTableHeading === "Stops") {
      handleDelete(item, onClose());
    } else {
      handleDelete(item).then((res: any) => {
        console.log("this is my response", res);
        if (!res?.detail) {
          setLoading(false);
          onClose();
          toast({
            title: "Success",
            description: `${
              dataTableHeading?.slice(0, -1) || dataTableHeading
            } was deleted successfully!`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else if (
          res?.detail.includes("psycopg2.errors.ForeignKeyViolation")
        ) {
          console.log("hskjdnvjk", res);
          toast({
            title: `Can't Delete ${dataTableHeading?.slice(0, -1)}`,
            description: `${dataTableHeading?.slice(
              0,
              -1
            )} is linked to other dependencies.`,
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        } else if (res?.detail.includes("psycopg2.errors.NotNullViolation")) {
          toast({
            title: `Can't Delete ${dataTableHeading.slice(0, -1)}`,
            description: `${dataTableHeading?.slice(
              0,
              -1
            )} is linked to other dependencies.`,
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        } else {
          // TODO: PLACE A NOTIFICATION HERE FOR ERRORS
          toast({
            title: `Can't Delete ${dataTableHeading?.slice(0, -1)}`,
            description: `${res?.detail}`,
            status: "warning",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        }
      });
      onClose();
    }
  };

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: pageSizeLimit,
      },
    },
  });

  return (
    <div>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {`Delete ${dataTableHeading.split("s")[0]}`}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => deleteItem(item)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {isSearch ? (
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex
            alignSelf={"start"}
            marginTop="1rem"
            width="30%"
            style={{ marginBottom: "10px" }}
          >
            <SearchBar
              type="text"
              marginEnd="1rem"
              placeholderText="Search"
              // value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              // onChange={(e) => console.log(e.target.value)}
            />
          </Flex>
          <Spacer />

          {/* Loading Modal */}
          <Modal isOpen={downloadLoading !== ""} onClose={() => {}}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Download in Progress</ModalHeader>
              <ModalBody>
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap={4}
                >
                  <Spinner size="xl" />
                  <Text>
                    Downloading {downloadLoading.toUpperCase()} file...
                  </Text>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>

          {isDownloadActions ? (
            // <Box>{JSON.stringify(data)}</Box>
            <ButtonGroup gap="2">
              {/* <Button
                id="element-to-convert-to-pdf"
                colorScheme="red"
                leftIcon={<FileTextIcon />}
                onClick={() =>
                  exportData({ data, fileName: "test-example" }, "pdf")
                }
              >
                PDF
              </Button> */}
              <Button
                colorScheme="blue"
                leftIcon={<FileTextIcon />}
                onClick={() => {
                  setDownloadLoading("csv");
                  exportData({ data, fileName: "test-example" }, "csv");
                  setDownloadLoading("");
                }}
                isLoading={downloadLoading === 'csv'}
                isDisabled={downloadLoading !== ''}
              >
                CSV
              </Button>
              <Button
                colorScheme="green"
                leftIcon={<FileTextIcon />}
                onClick={() => {
                  setDownloadLoading("excel");
                  exportData({ data, fileName: "test-example" }, "xlsx");
                  setDownloadLoading("");
                }}
                isLoading={downloadLoading === 'excel'}
                isDisabled={downloadLoading !== ''}
              >
                EXCEL
              </Button>
            </ButtonGroup>
          ) : (
            <></>
          )}
          <Flex></Flex>
        </Flex>
      ) : (
        <></>
      )}

      <Box boxShadow={"md"} minH={"auto"} h={"auto"} borderRadius={8} pt={2}>
        {table.getRowModel().rows.length > 0 ? (
          <Table variant={"simple"} size={"sm"}>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} onClick={() => handleSort(header.id)}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"2"}
                        cursor={"pointer"}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {sorting.column === header.id &&
                          // Show sorting indicator based on sorting state
                          (sorting.direction === "asc" ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          ))}
                      </Box>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {renderColumn(cell.column.id, cell, row)}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <>
            {isLoading ? (
              <Box>
                <Card align={"center"}>
                  <CardBody>
                    <Flex
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"4"}
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        colorScheme="green"
                        size="xl"
                      />

                      <Text
                        fontWeight={"bold"}
                        fontSize={"xl"}
                        align={"center"}
                      >
                        Loading {dataTableHeading} ...{" "}
                      </Text>
                    </Flex>
                  </CardBody>
                </Card>
              </Box>
            ) : (
              <Box pt={4}>
                <Card align="center">
                  <CardHeader>
                    <Heading size="md">{dataTableHeading}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>{`No ${dataTableHeading} data to show`}</Text>
                  </CardBody>
                  <CardFooter>
                    <Button
                      colorScheme="green"
                      onClick={() => table.setPageIndex(0)}
                    >
                      Go back
                    </Button>
                  </CardFooter>
                </Card>
              </Box>
            )}
          </>
        )}
      </Box>
      <Flex justifyContent="flex-end" alignItems="center " gap={1} my={4}>
        <Button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <ArrowLeftIcon />
        </Button>
        <Button
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          <ArrowForwardIcon />
        </Button>
        <Button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <ArrowRightIcon />
        </Button>
        <Flex justifyContent="flex-end" gap={4} ml={4}>
          <Flex alignItems="center" gap={1}>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </Flex>
          <Flex justifyContent="flex-end" alignItems="center" gap={1}>
            | <Box ml={3}>Go to page:</Box>
            <Input
              w={20}
              textAlign={"center"}
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default Datatable;
